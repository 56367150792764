<template>
  <ValidationProvider :rules="rules" :name="name" v-slot="{ errors, invalid, validated }" :mode="mode" :immediate="immediate">
    <input :type="type" v-model="currentValue">
    <slot name="msgError" v-bind:errors="errors" v-bind:invalid="invalid" v-bind:validated="validated"><span class="msgError">{{ errors[0] }}</span></slot>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'TextInput',
  components: {
    ValidationProvider
  },
  props: {
    value: {
      required: true
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    mode: {
      type: String,
      default: 'passive'
    },
    immediate: {
      type: Boolean,
      default: false
    },
    cssClass: {
      type: String,
      required: false
    }
  },
  data: () => ({
    currentValue: ''
  }),
  created() {
    this.currentValue = this.value;
  },
  watch: {
    currentValue (val) {
      this.$emit('input', val);
    },
    value: {
      handler(newValue) {
        this.currentValue = newValue;
      },
      immediate: true
    }
  }
};
</script>


