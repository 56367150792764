<template>
  <div class="btn-group enum-select" tabindex="0" :class="{ disabled: disabled }">
    <div @click="toggleMenu()" class="dropdown-toggle" v-click-outside="hide">{{ selectedOptionText }}<span class="caret"></span></div>
    <ul class="dropdown-menu" v-bind:class="{ 'hide' : !isMenuVisible}">
      <li v-for="(option, index) in options" :key="index">
        <a href="#" @click.prevent="setSelectedOption(option.value)">{{ $t(option.label) }}</a>
      </li>
    </ul>
    <text-input v-model="selectedValue" :name="validationName" :rules="validationRules" type="hidden" mode="aggressive" />
  </div>
</template>

<script>
  import TextInput from './TextInput.vue';
  import ClickOutside from 'vue-click-outside';

  export default {
    directives: {
      ClickOutside
    },
    components: {
      TextInput
    },
    data() {
      return {
        isMenuVisible: false,
        options: [],
        selectedValue: null
      };
    },
    props: {
      value: {
        type: [String, Number, Object]
      },
      exceptedValues: {
        type: Array
      },
      defaultValue: {
        type: [String, Number, Object]
      },
      enum: {
        type: Object,
        required: true
      },
      ressourceName: {
        type: String,
        required: true
      },
      optionsCaption: {
        type: String,
        required: false
      },
      validationRules: {
        type: [String, Object],
        required: false
      },
      validationName: {
        type: String,
        required: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    created() {
      this.setOptions();
      if ((this.defaultValue || this.defaultValue === 0) && !this.selectedValue) {
        this.selectedValue = this.defaultValue;
      }
    },
    methods: {
      setSelectedOption(option) {
        this.selectedValue = option;
        this.isMenuVisible = false;
      },
      toggleMenu() {
        if (!this.disabled) {
          this.isMenuVisible = !this.isMenuVisible;
        }
      },
      isInclude(value) {
        return !this.exceptedValues || !this.exceptedValues.includes(value);
      },
      hide() {
        this.isMenuVisible = false;
      },
      setOptions() {
        this.options = [];
        Object.keys(this.enum).forEach((property) => {
          var optionValue = this.enum[property];
          if (this.isInclude(optionValue)) {
            this.options.push({ label: this.ressourceName + '.' + property, value: optionValue });
          }
        });
      }
    },
    computed: {
      selectedOptionText() {
        var foundElement = this.options.find(elem => elem.value === this.selectedValue);
        if (foundElement) {
          return this.$t(foundElement.label);
        }
        return this.optionsCaption ? this.optionsCaption : '';
      }
    },
    watch: {
      exceptedValues() {
        this.setOptions();
      },
      value: {
        handler(newValue) {
          this.selectedValue = newValue;
        },
        immediate: true
      },
      selectedValue(newValue) {
        this.$emit('input', newValue);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .btn-group {
    outline: none;
    position: relative;
  }

    .btn-group .dropdown-toggle {
      background: transparent;
      border: none;
      width: 100%;
      border: 0.0625rem solid #dadada;
      border-radius: 0.625rem;
      cursor: pointer;
      padding: 0 0.875rem;
      height: 3.125rem;
      line-height: 3.125rem;
      box-sizing: border-box;
      color: #000;
      position: relative;
    }

      .btn-group .dropdown-toggle:hover,
      .btn-group .dropdown-toggle.select {
        border-color: #0E848D;
      }

      .btn-group .dropdown-toggle:after {
        /*content: "\F107";
        font-family: "Font Awesome 5 Pro";*/
        content:"";
        width: 0; 
        height: 0; 
        border-left: 5px solid transparent;
        border-right:5px solid transparent;
        border-top: 5px solid #000;
        display: inline-block;
        font-size: 1em;
        font-weight: normal;
        position: absolute;
        right: 1rem;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        pointer-events: none;
      }

    .btn-group .dropdown-menu.hide {
      display: none;
    }

    .btn-group .dropdown-menu {
      display: block;
      border: 0.0625rem solid #EAEAEA;
      padding: 0;
      margin: 0;
      width: 100%;
      position: absolute;
      left: 0;
      top: 50px;
      background-color: #fff;
      z-index: 10;
    }

      .btn-group .dropdown-menu li {
        list-style: none;
      }

        .btn-group .dropdown-menu li a {
          color: #000;
          display: block;
          padding: 0 0.875rem;
          text-decoration: none;
        }

          .btn-group .dropdown-menu li a:hover {
            background-color: #0E848D;
            color: #fff;
          }
</style>
